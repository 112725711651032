@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";
@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/themes/cao/imports";

$name: '.CaoContainerFixed';

#{$name} {
    &-whiteText {
        color: white;

        a {
            color: white;

            &.btn {
                border-color: $white;
                background-color: transparent;

                &:hover {
                    color: $cao-dark-blue;
                    background-color: $white;
                }
            }
        }
    }
}
